import { CONFIRM_PAYMENT_SUCCESS } from '../containers/CheckoutPage/CheckoutPage.duck';
import { PUBLISH_LISTING_SUCCESS } from '../containers/EditListingPage/EditListingPage.duck';
import { SIGNUP_SUCCESS } from '../ducks/auth.duck';
import { LOCATION_CHANGED } from '../ducks/routing.duck';

// Create a Redux middleware from the given analytics handlers. Each
// handler should have the following methods:
//
// - trackPageView(canonicalPath, previousPath): called when the URL is changed
export const createMiddleware = handlers => store => next => action => {
  const { type, payload } = action;
  const { analiticsData } = payload || {};

  switch (type) {
    case LOCATION_CHANGED:
      const previousPath = store?.getState()?.routing?.currentCanonicalPath;
      const { canonicalPath } = payload;

      handlers.forEach(handler => {
        handler.trackPageView(canonicalPath, previousPath);
      });
      break;

    case SIGNUP_SUCCESS:
      handlers.forEach(handler => {
        handler.trackAccountCreated(analiticsData);
      });

      break;

    case PUBLISH_LISTING_SUCCESS:
      handlers.forEach(handler => {
        handler.trackListingAdded(analiticsData);
      });

      break;

    case CONFIRM_PAYMENT_SUCCESS:
      handlers.forEach(handler => {
        handler.trackPurchase(analiticsData);
      });

      break;

    default:
      break;
  }

  next(action);
};
