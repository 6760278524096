import React, { useMemo } from 'react';
import { string } from 'prop-types';

import css from './ClothingConditionBadge.module.css';

const badges = [
  {
    abbr: 'NWT',
    accentColor: 'var(--colorNWT)',
  },
  {
    abbr: 'NWOT',
    accentColor: 'var(--colorNWOT)',
  },
  {
    abbr: 'EUC',
    accentColor: 'var(--colorEUC)',
  },
  {
    abbr: 'VGUC',
    accentColor: 'var(--colorVGUC)',
  },
  {
    abbr: 'GUC',
    accentColor: 'var(--colorGUC)',
  },
  {
    abbr: 'Play',
    accentColor: 'var(--colorPLAY)',
  },
];

export const ClothingConditionBadgeComponent = props => {
  const { kids_clothing_condition } = props;

  const { abbr, accentColor } = useMemo(
    () =>
      badges.find(
        ({ abbr }) => abbr?.toLocaleLowerCase() === kids_clothing_condition?.toLocaleLowerCase()
      ) || {},
    [kids_clothing_condition]
  );

  return abbr ? (
    <div style={{ backgroundColor: accentColor }} className={css.root}>
      {abbr}
    </div>
  ) : null;
};

ClothingConditionBadgeComponent.defaultProps = {
  kids_clothing_condition: null,
};

ClothingConditionBadgeComponent.propTypes = {
  kids_clothing_condition: string.isRequired,
};

export default ClothingConditionBadgeComponent;
