import React, { useMemo } from 'react';
import { node, oneOf, string } from 'prop-types';
import classNames from 'classnames';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import IconQuestionMark from '../IconQuestionMark/IconQuestionMark';

import css from './Tooltip.module.css';

const Tooltip = props => {
  const { id, className, triggerClassName, trigger, children, ...restOfProps } = props;

  const tooltipTrigger = useMemo(
    () => (
      <span data-tip data-tooltip-id={id} className={classNames(css.trigger, triggerClassName)}>
        <IconQuestionMark />
      </span>
    ),
    [id, triggerClassName]
  );

  const tooltipProps = useMemo(
    () => ({
      id,
      className: classNames(css.root, className),
      ...restOfProps,
    }),
    [id, className, restOfProps]
  );

  return (
    <>
      {trigger || tooltipTrigger}
      <ReactTooltip {...tooltipProps}>{children}</ReactTooltip>
    </>
  );
};

Tooltip.propTypes = {
  className: string,
  triggerClassName: string,
  id: string.isRequired,
  trigger: node,
  place: oneOf([
    'top',
    'top-start',
    'top-end',
    'right',
    'right-start',
    'right-end',
    'bottom',
    'bottom-start',
    'bottom-end',
    'left',
    'left-start',
    'left-end',
  ]),
};

export default Tooltip;
