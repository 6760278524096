import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconCamera.module.css';

const IconCamera = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg
      className={classes}
      height="24"
      width="18"
      viewBox="0 0 90 90"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M 81.039 21.623 H 70.026 c -3.069 0 -5.888 -1.704 -7.335 -4.432 l -1.472 -2.777 c -1.814 -3.421 -5.349 -5.557 -9.197 -5.557 H 37.978 c -3.848 0 -7.383 2.136 -9.197 5.557 l -0.464 0.876 l -1.008 1.901 c -0.983 1.854 -2.6 3.235 -4.499 3.93 c -0.895 0.328 -1.853 0.502 -2.836 0.502 H 8.961 C 4.012 21.623 0 25.668 0 30.657 v 41.452 c 0 4.989 4.012 9.034 8.961 9.034 h 72.079 c 1.835 0 3.541 -0.556 4.962 -1.51 C 88.412 78.014 90 75.249 90 72.109 V 30.657 C 90 25.668 85.988 21.623 81.039 21.623 z M 63.806 59.299 c -1.028 2.588 -2.565 4.914 -4.488 6.852 C 55.654 69.845 50.592 72.13 45 72.13 c -11.183 0 -20.249 -9.14 -20.249 -20.415 c 0 -5.637 2.266 -10.741 5.931 -14.435 c 1.923 -1.938 4.23 -3.489 6.797 -4.525 c 2.325 -0.938 4.863 -1.455 7.521 -1.455 c 11.183 0 20.249 9.14 20.249 20.415 C 65.249 54.395 64.737 56.954 63.806 59.299 z M 79.917 33.544 H 69.103 c -1.239 0 -2.244 -1.005 -2.244 -2.244 c 0 -1.239 1.005 -2.244 2.244 -2.244 h 10.814 c 1.239 0 2.244 1.005 2.244 2.244 C 82.161 32.54 81.157 33.544 79.917 33.544 z" />
      <path d="M 45 36.229 c -1.688 0 -3.305 0.289 -4.824 0.795 c 2.359 1.208 3.978 3.671 3.978 6.521 c 0 0.96 -0.184 1.878 -0.517 2.718 c -0.368 0.927 -0.919 1.761 -1.608 2.456 c -1.313 1.324 -3.128 2.143 -5.132 2.143 c -2.829 0 -5.273 -1.635 -6.471 -4.016 c -0.504 1.533 -0.786 3.166 -0.786 4.869 c 0 8.553 6.877 15.486 15.36 15.486 c 4.242 0 8.082 -1.733 10.861 -4.536 c 1.458 -1.47 2.625 -3.235 3.404 -5.198 c 0.706 -1.778 1.095 -3.72 1.095 -5.752 C 60.36 43.163 53.483 36.229 45 36.229 z" />
    </svg>
  );
};

IconCamera.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

IconCamera.propTypes = {
  rootClassName: string,
  className: string,
};

export default IconCamera;
