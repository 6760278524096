import classNames from 'classnames';
import React, { useMemo } from 'react';
import { Tooltip } from '../../components';
import { withViewport } from '../../util/uiHelpers';

import css from './InfoTooltip.module.css';

const MAX_MOBILE_SCREEN_WIDTH = 768;

const InfoTooltip = props => {
  const { className, triggerClassName, children, viewport, ...restOfProps } = props;

  const classes = useMemo(() => classNames(css.tooltip, className), [className]);
  const triggerClasses = useMemo(() => classNames(css.tooltipTrigger, triggerClassName), [
    triggerClassName,
  ]);

  const isMobile = useMemo(() => viewport.width < MAX_MOBILE_SCREEN_WIDTH, [viewport.width]);

  return (
    <Tooltip
      className={classes}
      triggerClassName={triggerClasses}
      place={isMobile ? 'bottom-start' : 'right'}
      variant="dark"
      {...restOfProps}
    >
      {children}
    </Tooltip>
  );
};

export default withViewport(InfoTooltip);
