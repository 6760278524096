import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconPencil.module.css';

const IconPencil = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg
      className={classes}
      width="21"
      height="22"
      viewBox="0 0 450 449.999984"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M 417.105469 109.476562 L 380.699219 145.914062 L 305.230469 70.382812 L 341.632812 33.949219 C 343.992188 31.589844 347.148438 30.289062 350.515625 30.289062 C 353.898438 30.289062 357.070312 31.597656 359.4375 33.960938 L 417.105469 91.675781 C 419.460938 94.042969 420.761719 97.203125 420.761719 100.585938 C 420.761719 103.960938 419.460938 107.117188 417.105469 109.476562 Z M 153.933594 372.863281 L 145.203125 351.472656 L 356.121094 140.386719 L 371.167969 155.449219 Z M 72.480469 404.367188 L 47.003906 378.875 L 70.664062 308.703125 L 95.796875 318.996094 L 102.535156 344.027344 C 103.15625 346.351562 104.976562 348.160156 107.289062 348.789062 L 132.304688 355.515625 L 142.589844 380.703125 Z M 34.046875 417.339844 L 42.199219 393.125 L 58.234375 409.179688 Z M 320.285156 104.515625 L 346.59375 130.847656 L 135.261719 342.347656 L 114.53125 336.777344 L 108.953125 316.027344 Z M 295.707031 79.917969 L 310.757812 94.984375 L 99.835938 306.078125 L 78.496094 297.34375 Z M 426.628906 82.144531 L 368.957031 24.421875 C 359.125 14.601562 341.910156 14.59375 332.109375 24.414062 L 61.804688 294.976562 C 61.089844 295.695312 60.488281 296.617188 60.125 297.785156 C 59.761719 298.957031 16.929688 425.925781 16.929688 425.925781 C 16.519531 427.140625 15.71875 429.6875 18.546875 432.847656 C 21.101562 435.695312 24.761719 434.703125 25.460938 434.464844 C 25.460938 434.464844 152.707031 391.609375 153.6875 391.179688 C 154.664062 390.753906 155.652344 390.207031 156.296875 389.5625 L 426.621094 119.019531 C 431.53125 114.113281 434.242188 107.566406 434.242188 100.585938 C 434.238281 93.597656 431.53125 87.054688 426.628906 82.144531 " />
    </svg>
  );
};

IconPencil.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

IconPencil.propTypes = {
  rootClassName: string,
  className: string,
};

export default IconPencil;
