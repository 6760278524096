import React from 'react';
import PropTypes, { bool, func, oneOf, shape } from 'prop-types';
import { Field } from 'react-final-form';
import classNames from 'classnames';
import { IconClose, ValidationError } from '../../components';

import css from './FieldSelect.module.css';

const FieldSelectComponent = props => {
  const {
    rootClassName,
    className,
    selectClassName,
    id,
    label,
    additionalLabel,
    input,
    meta,
    children,
    clearable,
    onClear,
    onChange,
    ...rest
  } = props;

  if (label && !id) {
    throw new Error('id required when a label is given');
  }

  const { valid, name, invalid, touched, error } = meta;

  // Error message and input error styles are only shown if the
  // field has been touched and the validation has failed.
  const hasError = touched && invalid && error;

  const selectClasses = classNames({
    [selectClassName]: selectClassName,
    [css.selectError]: hasError,
  });
  const handleChange = e => {
    input.onChange(e);
    if (onChange) {
      onChange(e.currentTarget.value);
    }
  };

  const selectProps = {
    className: selectClasses,
    id,
    ...input,
    onChange: handleChange,
    onKeyDown: e => (e.key === 'Enter' ? e.preventDefault() : null),
    ...rest,
  };

  const classes = classNames(rootClassName || css.root, className);

  const { type: additionalLabelType, content: additionalLabelContent } = additionalLabel || {};
  const labelClasses = classNames(css.label, { [css.labelRow]: additionalLabelType === 'tooltip' });

  return (
    <div className={classes}>
      {label ? (
        <div className={labelClasses}>
          <span>
            <label htmlFor={id}>{label}</label>
            {clearable && (
              <button type="button" className={css.clear} onClick={() => onClear(input.name)}>
                Clear
              </button>
            )}
          </span>
          {additionalLabelContent}
        </div>
      ) : null}

      <select {...selectProps}>{children}</select>
      <ValidationError fieldMeta={meta} />
    </div>
  );
};

FieldSelectComponent.defaultProps = {
  rootClassName: null,
  className: null,
  selectClassName: null,
  id: null,
  label: null,
  children: null,
  clearable: false,
  onClear: () => {},
};

const { string, object, node } = PropTypes;

FieldSelectComponent.propTypes = {
  rootClassName: string,
  className: string,
  selectClassName: string,

  // Label is optional, but if it is given, an id is also required so
  // the label can reference the input in the `for` attribute
  id: string,
  label: string,

  // Generated by final-form's Field component
  input: object.isRequired,
  meta: object.isRequired,

  clearable: bool,
  onClear: func,

  additionalLabel: shape({
    type: oneOf(['text', 'tooltip']),
    content: object,
  }),

  children: node,
};

const FieldSelect = props => {
  return <Field component={FieldSelectComponent} {...props} />;
};

export default FieldSelect;
