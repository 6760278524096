import React, { useMemo } from 'react';
import { string, func, bool } from 'prop-types';
import classNames from 'classnames';
import { useConfiguration } from '../../context/configurationContext';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import { displayPrice } from '../../util/configHelpers';
import { lazyLoadWithDimensions } from '../../util/uiHelpers';
import { propTypes } from '../../util/types';
import { convertNumberToMoney, formatMoney } from '../../util/currency';
import { ensureListing } from '../../util/data';
import { richText } from '../../util/richText';
import { createSlug } from '../../util/urlHelpers';
import { isBookingProcessAlias } from '../../transactions/transaction';
import { AspectRatioWrapper, NamedLink, ResponsiveImage } from '../../components';
import ClothingConditionBadge from './ClothingConditionBadge/ClothingConditionBadge';

import css from './ListingCard.module.css';

const MIN_LENGTH_FOR_LONG_WORDS = 10;

const priceData = (price, currency, intl) => {
  if (price && price.currency === currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: intl.formatMessage(
        { id: 'ListingCard.unsupportedPrice' },
        { currency: price.currency }
      ),
      priceTitle: intl.formatMessage(
        { id: 'ListingCard.unsupportedPriceTitle' },
        { currency: price.currency }
      ),
    };
  }
  return {};
};

const LazyImage = lazyLoadWithDimensions(ResponsiveImage, { loadAfterInitialRendering: 3000 });

const PriceMaybe = props => {
  const { price, publicData, config, intl } = props;
  const { listingType } = publicData || {};
  const validListingTypes = config.listing.listingTypes;
  const foundListingTypeConfig = validListingTypes.find(conf => conf.listingType === listingType);
  const showPrice = displayPrice(foundListingTypeConfig);
  if (!showPrice && price) {
    return null;
  }

  const isBookable = isBookingProcessAlias(publicData?.transactionProcessAlias);
  const { formattedPrice, priceTitle } = priceData(price, config.currency, intl);
  return (
    <div className={css.price}>
      <div className={css.priceValue} title={priceTitle}>
        {formattedPrice}
      </div>
      {isBookable ? (
        <div className={css.perUnit}>
          <FormattedMessage id="ListingCard.perUnit" values={{ unitType: publicData?.unitType }} />
        </div>
      ) : null}
    </div>
  );
};

const ShippingMaybe = props => {
  const { publicData, config, intl } = props;
  const { listingType, shippingFeeAmount } = publicData || {};
  const validListingTypes = config.listing.listingTypes;
  const foundListingTypeConfig = validListingTypes.find(conf => conf.listingType === listingType);
  const showPrice = displayPrice(foundListingTypeConfig);

  if (!showPrice) {
    return null;
  }

  const shipping = convertNumberToMoney(shippingFeeAmount || 0, config.currency);
  const { formattedPrice, priceTitle } = priceData(shipping, config.currency, intl);

  return (
    <div className={css.shipping}>
      <div className={css.shippingValue} title={priceTitle}>
        {shippingFeeAmount ? (
          <FormattedMessage id="ListingCard.shipping" values={{ formattedPrice }} />
        ) : (
          <FormattedMessage id="ListingCard.freeShipping" />
        )}
      </div>
    </div>
  );
};

export const ListingCardComponent = props => {
  const { rootClassName, className, listing, renderSizes, setActiveListing, intl } = props;

  const config = useConfiguration();
  const {
    aspectWidth = 1,
    aspectHeight = 1,
    variantPrefix = 'listing-card',
  } = config.layout.listingImage;
  const { listingFields: listingFieldsConfig } = config.listing;

  const currentListing = useMemo(() => ensureListing(listing), [listing]);
  const { id, images } = currentListing;
  const { title = '', price, publicData } = currentListing.attributes;
  const { kids_bamboo_brand, kids_clothing_size, kids_clothing_condition = '' } = publicData || {};

  const linkParams = useMemo(() => ({ id: id.uuid, slug: createSlug(title) }), [id, title]);
  const firstImage = useMemo(() => (images && images.length > 0 ? images[0] : null), [images]);
  const variants = useMemo(
    () =>
      firstImage
        ? Object.keys(firstImage?.attributes?.variants).filter(k => k.startsWith(variantPrefix))
        : [firstImage, variantPrefix],
    []
  );

  const setActivePropsMaybe = useMemo(
    () =>
      setActiveListing
        ? {
            onMouseEnter: () => setActiveListing(id),
            onMouseLeave: () => setActiveListing(null),
          }
        : null,
    [setActiveListing, id]
  );

  const brandLabel = useMemo(
    () =>
      (kids_bamboo_brand &&
        listingFieldsConfig
          ?.find(c => c.key === 'kids_bamboo_brand')
          ?.enumOptions?.find(o => o.option === kids_bamboo_brand)?.label) ||
      '',
    [listingFieldsConfig, kids_bamboo_brand]
  );
  const sizeLabel = useMemo(
    () =>
      (kids_clothing_size &&
        listingFieldsConfig
          ?.find(c => c.key === 'kids_clothing_size')
          ?.enumOptions?.find(o => o.option === kids_clothing_size)?.label) ||
      '',
    [listingFieldsConfig, kids_clothing_size]
  );

  const classes = useMemo(() => classNames(rootClassName || css.root, className), []);

  return (
    <NamedLink className={classes} name="ListingPage" params={linkParams}>
      <AspectRatioWrapper
        className={css.aspectRatioWrapper}
        width={aspectWidth}
        height={aspectHeight}
        {...setActivePropsMaybe}
      >
        <ClothingConditionBadge kids_clothing_condition={kids_clothing_condition} />
        <LazyImage
          rootClassName={css.rootForImage}
          alt={title}
          image={firstImage}
          variants={variants}
          sizes={renderSizes}
        />
      </AspectRatioWrapper>
      <div className={css.info}>
        <PriceMaybe price={price} publicData={publicData} config={config} intl={intl} />
        <ShippingMaybe
          publicData={publicData}
          config={config}
          listingFieldsConfig={listingFieldsConfig}
          intl={intl}
        />
        <div className={css.mainInfo}>
          <div className={css.text}>
            {richText(brandLabel, {
              longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
              longWordClass: css.longWord,
            })}
          </div>
          <div className={css.text}>
            {richText(sizeLabel, {
              longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
              longWordClass: css.longWord,
            })}
          </div>
        </div>
      </div>
    </NamedLink>
  );
};

ListingCardComponent.defaultProps = {
  className: null,
  rootClassName: null,
  renderSizes: null,
  setActiveListing: null,
  showAuthorInfo: true,
};

ListingCardComponent.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  listing: propTypes.listing.isRequired,
  showAuthorInfo: bool,

  // Responsive image sizes hint
  renderSizes: string,

  setActiveListing: func,
};

export default injectIntl(ListingCardComponent);
