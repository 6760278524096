import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconQuestionMark.module.css';

const IconQuestionMark = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg
      className={classes}
      width="21"
      height="22"
      viewBox="0 0 450 449.999984"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M 225 22.5 C 113.375 22.5 22.5 113.375 22.5 225 C 22.5 336.625 113.375 427.5 225 427.5 C 336.625 427.5 427.5 336.625 427.5 225 C 427.5 113.375 336.625 22.5 225 22.5 Z M 223.492188 354.867188 C 210.105469 354.867188 199.226562 343.988281 199.226562 330.601562 C 199.226562 317.214844 210.105469 306.335938 223.492188 306.335938 C 236.882812 306.335938 247.761719 317.214844 247.761719 330.601562 C 247.761719 343.988281 236.882812 354.867188 223.492188 354.867188 Z M 261.148438 240.730469 C 253.28125 245.417969 248.261719 254.453125 248.261719 264.664062 C 248.261719 278.050781 237.382812 288.929688 223.996094 288.929688 C 210.609375 288.929688 199.730469 278.050781 199.730469 264.664062 C 199.730469 237.382812 213.789062 212.113281 236.546875 198.894531 C 243.40625 194.707031 250.605469 185.503906 248.261719 172.617188 C 246.589844 163.078125 238.554688 155.046875 229.015625 153.203125 C 214.289062 150.359375 203.410156 160.066406 200.230469 170.441406 C 196.550781 183.328125 182.992188 190.859375 170.105469 187.011719 C 157.222656 183.328125 149.855469 169.773438 153.707031 156.886719 C 164.25 120.570312 200.566406 98.480469 238.054688 105.507812 C 267.339844 111.03125 290.769531 134.460938 296.125 163.914062 C 301.648438 194.710938 287.925781 225 261.148438 240.730469 Z M 261.148438 240.730469 " />
    </svg>
  );
};

IconQuestionMark.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

IconQuestionMark.propTypes = {
  rootClassName: string,
  className: string,
};

export default IconQuestionMark;
