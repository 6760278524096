import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconWand.module.css';

const IconWand = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg
      className={classes}
      width="21"
      height="22"
      viewBox="0 0 1575 1575"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill="var(--colorBlack)"
        d="M1079.136475,945.863464 C1154.641602,1021.517700 1229.910889,1096.908081 1305.178101,1172.300537 C1355.983887,1223.191040 1406.765137,1274.106079 1457.599854,1324.967651 C1486.378418,1353.761230 1486.502563,1396.139648 1457.799927,1424.843628 C1438.730591,1443.914062 1419.593750,1462.917847 1400.631470,1482.094116 C1386.899170,1495.981812 1370.036987,1502.878052 1350.908936,1502.760254 C1331.893311,1502.643311 1315.562378,1494.898438 1302.013916,1481.307617 C1223.984619,1403.034790 1145.909912,1324.807129 1067.815674,1246.598999 C987.359009,1166.024902 906.864990,1085.488159 826.382935,1004.939392 C719.057312,897.524780 611.744934,790.096924 504.379974,682.721619 C488.846252,667.186340 481.535461,648.433960 483.186249,626.736511 C484.304199,612.042725 489.563141,598.334473 500.051666,587.604248 C521.461548,565.700806 543.102966,544.013245 565.060730,522.660095 C576.440979,511.593231 590.547363,506.683563 606.583923,505.851440 C629.600037,504.657166 647.484680,513.594604 663.439209,529.601685 C801.788025,668.405518 940.380005,806.966980 1079.136475,945.863464 M675.546082,625.953857 C663.064270,613.481750 650.301697,601.275024 638.183716,588.458984 C627.196472,576.838867 603.024414,575.458496 592.108459,584.549194 C583.065491,592.080017 574.865295,600.643188 566.481018,608.940247 C549.709778,625.536804 549.727844,648.023804 566.395508,664.717773 C588.162231,686.518921 609.942688,708.306274 631.722046,730.094727 C665.501831,763.888428 699.263367,797.700439 733.077759,831.459534 C748.451843,846.808533 770.437988,847.411682 786.366577,832.672546 C794.174866,825.447205 801.719238,817.882263 808.845825,809.986084 C822.937500,794.372681 822.180969,772.705505 807.327515,757.829956 C763.583984,714.021240 719.800903,670.251831 675.546082,625.953857 z"
      />
      <path
        fill="var(--colorPink)"
        stroke="none"
        d="M917.296265,268.294647 C931.496399,256.850647 942.379517,243.092285 951.177124,227.630692 C968.407654,197.348404 977.202454,164.094360 984.928589,130.521744 C989.180237,112.046936 992.758789,93.417252 997.337952,74.764427 C998.870728,82.472458 1000.167664,90.236366 1001.978760,97.878426 C1008.367188,124.835190 1014.101013,151.986389 1021.670593,178.612534 C1028.788330,203.649384 1039.460205,227.321655 1055.909546,247.982590 C1067.363037,262.368530 1081.219727,273.886932 1097.074219,283.005280 C1125.934082,299.603424 1157.607178,308.491608 1189.720581,315.884552 C1209.592896,320.459442 1229.673462,324.128448 1249.656250,328.225067 C1249.920166,328.279114 1250.127808,328.607941 1251.130249,329.467682 C1245.017456,330.700653 1239.512207,331.852875 1233.990112,332.918213 C1194.993530,340.441406 1156.145020,348.592926 1119.347534,364.096405 C1099.277100,372.552490 1080.674561,383.632416 1065.397827,399.365112 C1050.292603,414.921021 1039.292603,433.267029 1031.002075,453.291992 C1016.945251,487.244354 1009.152039,522.905945 1001.945129,558.736084 C1000.250244,567.161926 998.607849,575.598450 996.134949,584.063049 C993.427307,570.672058 991.241882,557.149170 987.901123,543.917969 C981.362122,518.020203 975.055664,492.008331 967.022766,466.558258 C958.470093,439.461609 944.641846,414.966095 923.621155,395.270966 C907.137207,379.826477 887.662415,369.153625 866.682373,361.016388 C834.025635,348.350281 799.994629,340.885376 765.796509,334.019897 C758.073914,332.469543 750.328613,331.032379 742.555481,328.540100 C750.392029,327.045746 758.242554,325.620819 766.062683,324.044891 C798.786072,317.450409 831.384583,310.298035 862.726746,298.548370 C882.258118,291.226410 900.997498,282.275909 917.296265,268.294647 z"
      />
      <path
        fill="var(--colorGreen)"
        d="M236.817917,295.841064 C262.925598,278.622772 278.932037,254.144882 289.907013,225.932083 C302.508514,193.538025 309.448303,159.644516 316.060028,125.675789 C316.757141,122.094246 317.479919,118.517685 318.922791,114.829742 C320.616699,123.457573 322.242859,132.099350 324.016571,140.710754 C330.596680,172.657669 337.733856,204.432739 350.589691,234.625290 C365.982178,270.775299 391.808105,296.092316 428.139832,311.029327 C458.156921,323.370270 489.570892,330.508453 521.183960,337.032440 C528.955017,338.636169 536.721741,340.260529 544.468201,342.855103 C532.852417,345.226349 521.218811,347.514069 509.624420,349.985718 C483.103088,355.639374 456.860565,362.115814 431.480682,372.026917 C388.280426,388.897064 360.377747,419.783875 344.953796,463.000366 C334.066315,493.506042 327.259827,524.997009 321.002533,556.654968 C320.133362,561.052429 319.430573,565.482666 317.980865,570.065674 C316.328735,561.867126 314.701721,553.663391 313.019989,545.470947 C306.193726,512.216858 298.908112,479.126038 285.226959,447.784027 C269.681610,412.171265 243.535309,387.870361 207.913574,373.080536 C175.742889,359.723541 141.848831,352.886658 107.954933,345.974518 C102.583794,344.879150 97.199295,343.849396 91.610336,341.986542 C94.117249,341.365204 96.601860,340.628113 99.134483,340.140259 C128.695221,334.446259 158.200455,328.496277 186.851486,319.056976 C204.281448,313.314514 221.197281,306.373688 236.817917,295.841064 z"
      />
      <path
        fill="var(--colorYellow)"
        d="M283.666962,1003.389648 C267.003448,967.698425 240.120117,943.758545 203.498489,930.782776 C188.075043,925.317932 172.555527,920.007996 156.801392,915.628784 C143.588898,911.955994 129.973557,909.730835 116.533783,906.877930 C109.144936,905.309509 101.750359,903.768188 94.358360,902.214661 C94.334412,901.742432 94.310471,901.270264 94.286522,900.798035 C99.024590,899.578613 103.719788,898.144470 108.510315,897.187744 C116.560799,895.580078 124.794144,894.757751 132.732025,892.743713 C149.221649,888.559937 165.570038,883.818848 181.972946,879.294006 C199.936630,874.338623 216.896957,867.090515 232.805954,857.339661 C255.425903,843.475525 271.686920,824.060120 283.105042,800.336731 C297.062073,771.338257 304.427704,740.315552 311.071869,709.082031 C313.309967,698.560974 315.232025,687.972717 317.328827,677.421265 C317.381256,677.157471 317.788971,676.964355 318.975159,675.856262 C320.443146,683.212708 321.641785,689.954529 323.144379,696.627808 C331.529541,733.867065 338.819061,771.406921 356.577606,805.826660 C371.852936,835.433472 395.400208,855.570862 425.518127,868.965698 C442.176819,876.374634 459.509308,881.650757 477.014221,886.231506 C493.821503,890.629700 511.009918,893.569031 528.025513,897.174805 C534.004883,898.441895 539.968323,899.784363 545.908203,901.826355 C533.110229,904.237549 520.280518,906.494202 507.524872,909.111694 C498.291504,911.006531 489.073883,913.083679 479.997467,915.611755 C467.402924,919.119751 454.862488,922.869446 442.447449,926.965881 C396.608002,942.090698 364.079529,971.234009 346.883270,1017.027527 C337.435181,1042.187744 331.104004,1068.127441 325.211426,1094.228638 C322.787628,1104.964966 320.761810,1115.791016 318.026794,1126.688721 C308.817780,1085.062744 301.766998,1042.862793 283.666962,1003.389648 z"
      />
    </svg>
	  
	  
	  
  );
};

IconWand.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

IconWand.propTypes = {
  rootClassName: string,
  className: string,
};

export default IconWand;
